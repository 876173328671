/* eslint-disable no-irregular-whitespace */
// @ts-nocheck
import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import Layout from '../components/Layout';
import Title from '../components/Title';
import heroImage from '../images/banner-4.jpg';

const H2 = styled.h2`
  color: #004165;
  font-size: 1.8em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
`;
const H3 = styled.h3`
  color: #004165;
  font-size: 1.3em;
  text-align: center;
  margin-bottom: 1em;
  margin-top: 2.5em;
  font-weight: 300;
`;

const Element = styled.li`
  padding-left: 7px;
`;

const ParagraphContainer = styled(Row)`
  padding: 1.5em;
  & li {
    padding-bottom: 0.5em;
  }
`;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TermsAndConditionsPage = () => (
  <Layout>
    <Wrapper>
      <Title
        imageUrl={heroImage}
        text="Terms and Conditions"
      />
      <ParagraphContainer>
        <Col sm={12} lg={8} className="mx-auto">
          <H2>Virtual Homestay ENGLISH Terms and Conditions</H2>
          <div id="customer-fees">
            <H3>VIRTUAL HOMESTAY ENGLISH (VHE) CUSTOMER FEES AND CHARGES POLICY</H3>
            <ol>
              <Element>
                  OVERVIEW
                <ol type="A">
                  <Element>
                      This policy outlines MyStay International Pty Ltd (“MSI”) principles and guidelines for the Customer fees, costs and charges for the Virtual Homestay ENGLISH (“VHE”) program.
                  </Element>
                  <Element>
                      The Australian Homestay Network (“AHN”) is owned by MSI and is the key supplier of the VHE program. References to “VHE Supplier/s” include AHN.
                  </Element>
                  <Element>
                      A “Customer” is defined as:
                    <ol type="i">
                      <Element>
                        Someone who has applied for the VHE program as an English learning participant, received an invoice from AHN, and has made full payment of the invoice to AHN.
                      </Element>
                    </ol>
                  </Element>
                  <Element>
                    “Program Fee” refers to the fee paid to confirm that a placement is required. It covers host matching, program administration, system support and supplier costs. The Program Fee is not refundable once payment has been received, except in the case of a VHE Supplier default when 100% of Program Costs are refunded.
                  </Element>
                  <Element>
                    “Package Fee” refers to the <a href="/en/program-and-pricing">Virtual Homestay ENGLISH package</a> selected by the Customer.
                  </Element>
                  <Element>
                    “Program Costs” refers to the total of the Program Fee plus the Package Fee. This is the amount that will be outlined on the Customer’s invoice.
                  </Element>
                  <Element>
                    This policy complies with the Australian Consumer Law,
                    set out in Schedule 2 of the
                    {' '}
                    <a href="https://www.legislation.gov.au/Latest/C2018C00437">Competition and Consumer Act 2010</a>.
                  </Element>
                  <Element>
                    Customers are required to pay Program Costs in full in order for AHN to match them with a host Mentor and confirm their placement, agree to all terms and conditions associated with the program and its Suppliers, and confirm the start date for the commencement of the program (“Commencement Date”).
                  </Element>
                  <Element>
                      The VHE program contains the following items either for the duration or intermittently throughout the program:
                    <ol type="i">
                      <Element>
                        One-on-One online lessons with a specially trained English-speaking host Mentor (minimum 28 hours over 4 weeks)
                      </Element>
                      <Element>
                        Cultural immersion
                      </Element>
                      <Element>
                        Access to the Reallyenglish online platform (16 weeks)
                      </Element>
                      <Element>
                        Certificate of Completion
                      </Element>
                    </ol>
                  </Element>
                  <Element>
                    All Customers must accept and adhere to any MSI Policies or policies of any VHE Suppliers for the duration of the program. Policies are in effect at the time of application to the program.
                  </Element>
                  <Element>
                    If you are an Agent acting on behalf of a Customer, you must pass this information on to the Customer.
                  </Element>
                </ol>
              </Element>
              <Element>
                  CURRENCY AND DESTINATION
                <ol type="A">
                  <Element>
                      The VHE program is implemented with VHE Mentors in Australia (and throughout popular English-speaking destinations across the globe as required).
                    <ol type="i">
                      <Element>
                        MSI will offer the VHE program in Australian Dollars (AUD).
                      </Element>
                      <Element>
                        These costs may be represented as NET of any local, state or federal taxes that may be applied and invoiced at the time of payment and payable by the Customer.
                      </Element>
                      <Element>
                        These costs may be represented as NET of any bank charges that may be incurred by MSI and payable by the Customer.
                      </Element>
                      <Element>
                        MSI is not responsible for foreign currency or foreign exchange gains or losses incurred by the Customer in paying for the VHE program.
                      </Element>
                      <Element>
                        MSI may use a third-party payment services provider in retail sales of the VHE program.
                        <ol>
                          <Element>
                            MSI is not responsible for any additional fees or charges incurred by the Customer in the use of any third-party payment service providers.
                          </Element>
                          <Element>
                            Customers are bound by the terms of service of said service providers when engaging with these services.
                          </Element>
                        </ol>
                      </Element>
                    </ol>
                  </Element>
                </ol>
              </Element>
              <Element>
                CUSTOMER FEES AND CHARGES
                <ol type="A">
                  <Element>
                    VHE program costs are determined by MSI and may be updated intermittently due to commercial considerations made by MSI.
                    <ol type="i">
                      <Element>
                        VHE Program Costs and charges are locked at the time of invoice to the student upon application for the program.
                      </Element>
                      <Element>
                        VHE reserves the right to uphold quoted costs at the time of application to the program.
                      </Element>
                    </ol>
                  </Element>
                  <Element>
                    VHE Program Costs are inclusive of all services and products outlined in the promotional material of VHE.
                    <ol type="i">
                      <Element>
                        VHE reserves the right not to divulge the individual costs of any component, service or product contained within the promotional material of the VHE program.
                      </Element>
                      <Element>
                        The VHE program is a complete packaged product, sold at retail or through contracted parties offering the VHE program, and engagement by the Customer in any component of the VHE program indicates, for the purposes of VHE policy, use of the VHE package.
                      </Element>
                    </ol>
                  </Element>
                  <Element>
                      MSI may use contracted sales representatives not employed by VHE at its behest.
                    <ol type="i">
                      <Element>
                        Any payment or commissions are considered commercial in confidence and will not be disclosed to the Customer.
                      </Element>
                      <Element>
                        MSI insists on a high standard of ethics from contractors and will ensure that the price of the VHE program purchased through a contractor will be comparable to the retail price of the VHE program purchasable by the Customer directly.
                      </Element>
                    </ol>
                  </Element>
                </ol>
              </Element>
              <Element>
                REVIEW PROCESS
                <ol type="A">
                  <Element>
                    If a Customer is not satisfied with the outcome of a refund decision made under the Virtual Homestay English (VHE) Cancellation & Refund Policy, but in consideration of this policy, the review process described in the ‘Virtual Homestay English (VHE) Grievance Resolution Policy’ will apply.
                  </Element>
                  <Element>
                    This written agreement, and the right to make complaints and seek appeals of decisions and action under various processes, does not affect the rights of the Customer to take action under the Australian Consumer Law if the Australian Consumer Law applies.
                  </Element>
                </ol>
              </Element>
            </ol>
          </div>
          <div id="deferral-policy">
            <H3>
                VIRTUAL HOMESTAY ENGLISH (VHE) CANCELLATION & REFUND POLICY
            </H3>
            <ol>
              <Element>
                  OVERVIEW
                <ol type="A">
                  <Element>
                    This policy outlines MyStay International Pty Ltd (“MSI”) principles and guidelines for Customer deferment, cancellation and refunds for the Virtual Homestay ENGLISH (“VHE”) program.
                  </Element>
                  <Element>
                    The Australian Homestay Network (“AHN”) is owned by MSI and is the key supplier of the VHE program. References to “VHE Supplier/s” include AHN.
                  </Element>
                  <Element>
                    A “Customer” is defined as:
                    <ol type="i">
                      <Element>
                        Someone who has applied for the VHE program as an English learning participant, received an invoice from AHN, and has made full payment of the invoice to AHN.
                      </Element>
                    </ol>
                  </Element>
                  <Element>
                    “Program Fee” refers to the fee paid to confirm that a placement is required. It covers host matching, program administration, system support and supplier costs. The Program Fee is not refundable once payment has been received, except in the case of a supplier default when 100% of Program Costs are refunded.
                  </Element>
                  <Element>
                    “Package Fee” refers to the <a href="/en/program-and-pricing">Virtual Homestay ENGLISH package</a> selected by the Customer.
                  </Element>
                  <Element>
                    “Program Costs” refers to the total of the Program Fee plus the Package Fee. This is the amount that will be outlined on the Customer’s invoice.
                  </Element>
                  <Element>
                    This policy complies with the Australian Consumer Law, set out in Schedule 2 of the
                    {' '}
                    <a href="https://www.legislation.gov.au/Latest/C2018C00437">Competition and Consumer Act 2010</a>.
                  </Element>
                  <Element>
                    In accordance with the published Virtual Homestay ENGLISH ‘Customer Fees and Charges Policy’, Customers are required to pay Program Costs in full in order for AHN to match them with a host Mentor and confirm their placement, agree to all terms and conditions associated with the program and its Suppliers, and confirm the start date for the commencement of the program (“Commencement Date”).
                  </Element>
                  <Element>
                    The VHE program consists of a scheduled program containing the following items either for the duration or intermittently throughout the program:
                    <ol type="i">
                      <Element>
                        One-on-One online lessons with a specially trained English-speaking host Mentor (minimum 28 hours over 4 weeks)
                      </Element>
                      <Element>
                        Cultural immersion
                      </Element>
                      <Element>
                        Access to the Reallyenglish online platform (16 weeks)
                      </Element>
                      <Element>
                        Certificate of Completion
                      </Element>
                    </ol>
                  </Element>
                  <Element>
                    All Customers must accept and adhere to any MSI Policies or policies of any VHE Suppliers for the duration of the program. Policies are in effect at the time of application to the program.
                  </Element>
                </ol>
              </Element>
              <Element>
                REFUNDS – CANCELLATION
                <ol type="A">
                  <Element>
                    A Customer who withdraws in writing (“Cancellation”) from the VHE program:
                    <ol type="i">
                      <Element>
                        At least 14 days prior to the Commencement Date will be charged a cancellation fee of 20% of the retail Package Fee (“Cancellation Fee”).
                      </Element>
                      <Element>
                        Less than 14 days prior to the Commencement Date will be charged 100% of Package Fee (“Forfeit Fee”), however,  rescheduling may be available subject to Mentor agreement.
                      </Element>
                      <Element>
                        The Program Fee is non-refundable.
                      </Element>
                    </ol>
                  </Element>
                  <Element>
                    A Customer who does not commence or abandons (“Non-Commencement”) the VHE program:
                    <ol type="i">
                      <Element>
                        On or after the Commencement Date, will be charged 100% of Program Costs (“Forfeit Fee”).
                      </Element>
                    </ol>
                  </Element>
                </ol>
              </Element>
              <Element>
                RESCHEDULING OF ONE-ON-ONE ONLINE SESSIONS
                <ol type="A">
                  <Element>
                    If a Customer wishes to reschedule a session they must provide written notice to their Mentor at least 48 hours prior to the scheduled date and time of the session (unless the Mentor agrees otherwise).
                  </Element>
                  <Element>
                    Mentors will make all reasonable attempts to accommodate changes, however, if the Mentor does not receive written notice at least 48 hours prior to the scheduled date and time of the session, or a participant does not attend a scheduled session, there is no obligation on the Mentor to conduct that session.
                  </Element>
                </ol>
              </Element>
              <Element>
                EXCLUSION
                <ol type="A">
                  <Element>
                    The following conditions will apply to Customers who are excluded from the program due to misconduct outlined in the MSI Customer Conduct policy, or a policy of a VHE Supplier:
                    <ol type="i">
                      <Element>
                        on or after the Commencement Date, will be charged 100% of Program Costs (“Forfeit Fee”).
                      </Element>
                    </ol>
                  </Element>
                </ol>
              </Element>
              <Element>
                SUPPLIER DEFAULT
                <ol type="A">
                  <Element>
                    In the unlikely event that MSI cannot find an appropriate Mentor for the Customer’s required dates, MSI will provide 100% refund of the Program Costs minus any bank, transfer, or foreign exchange costs associated with the Customer’s original payment.
                  </Element>
                  <Element>
                    In the unlikely event that MSI is unable to deliver a program as offered, any Program Costs paid by the Customer for the program will be refunded in full within 28 days after the agreed Commencement Date of the program or within 28 days after the notification that the program is no longer offered.
                  </Element>
                </ol>
              </Element>
              <Element>
                PAYMENT OF REFUNDS
                <ol type="A">
                  <Element>
                  Requests for VHE program refunds, either in full or in part, must be submitted in writing with any relevant supporting documentation.
                  </Element>
                  <Element>
                  Any refund due will be returned to the source account from which the Customer’s original payment was made.
                  </Element>
                  <Element>
                  Refunds will be paid within 28 days of receipt of an approved refund application with all required supporting documentation.
                  </Element>
                </ol>
              </Element>
              <Element>
                REVIEW PROCESS
                <ol type="A">
                  <Element>
                    If a Customer is not satisfied with the outcome of a refund decision made under this policy, the review process described in the ‘VHE Grievance Resolution Policy’ will apply.
                  </Element>
                  <Element>
                    This written agreement, and the right to make complaints and seek appeals of decisions and action under various processes, does not affect the rights of the Customer to take action under the Australian Consumer Law if the Australian Consumer Law applies.
                  </Element>
                </ol>
              </Element>
            </ol>
          </div>
          <div id="mentor-deliver">
            <H3>VIRTUAL HOMESTAY ENGLISH (VHE) MENTOR DELIVERABLES</H3>
            <ol>
              <Element>
                OVERVIEW
                <ol type="A">
                  <Element>
                    This policy outlines MyStay International Pty Ltd (“MSI”) principles and guidelines for delivery of services for approved Mentors for the Virtual Homestay ENGLISH (“VHE”) program.
                  </Element>
                  <Element>
                    The Australian Homestay Network (“AHN”) is owned by MSI and is the key supplier of the VHE program. References to “VHE Supplier/s” include AHN.
                  </Element>
                  <Element>
                    A “Customer” is defined as:
                    <ol type="i">
                      <Element>
                        Someone who has applied for the VHE program as an English learning participant, received an invoice from AHN, and has made full payment of the invoice to AHN.
                      </Element>
                    </ol>
                  </Element>
                  <Element>
                    A “Mentor” is defined as:
                    <ol type="i">
                      <Element>
                        Having completed the “VHE Mentor Training program” available at the time and hold the supplied completion certificate (if applicable).
                      </Element>
                      <Element>
                        If training by the Mentor has eclipsed 12-month period since completion of the training, the Mentor has been reassessed by the Supplier as competently trained.
                      </Element>
                    </ol>
                  </Element>
                  <Element>
                    The VHE program contains the following items either for the duration or intermittently throughout the program:
                    <ol type="i">
                      <Element>
                        One-on-One online lessons with a specially trained English-speaking host Mentor (minimum 28 hours over 4 weeks)
                      </Element>
                      <Element>
                        Cultural immersion
                      </Element>
                      <Element>
                        Access to the Reallyenglish online platform (16 weeks)
                      </Element>
                      <Element>
                        Certificate of Completion
                      </Element>
                    </ol>
                  </Element>
                  <Element>
                    All Customers and Mentors must accept and adhere to any MSI Policies or policies of any VHE Suppliers for the duration of the program. Policies are in effect at the time of application to the program.
                  </Element>
                  <Element>
                    Components of the program deliverable by the Mentor include:
                    <ol type="i">
                      <Element>
                        Availability to host scheduled One-one-One online sessions with the Customer for the period of the placement (minimum 28 hours total for the Starter Package and minimum 14 hours total for the Extension Package).
                      </Element>
                      <Element>
                        Access to a reliable internet connection, webcam and online meeting platform (e.g. Zoom, Skype, etc.) for the purposes of completing the VHE program.
                      </Element>
                      <Element>
                        Flexibility in scheduling the One-one-One online sessions with the Customer based on time zones and availability.
                      </Element>
                      <Element>
                        Confirming bookings in writing with the Customer e.g. via email and/or virtual calendar.
                      </Element>
                      <Element>
                        Being flexible to accommodate any reasonable changes to the scheduled online sessions required by the Customer.
                      </Element>
                      <Element>
                        Providing a safe and supportive environment for the Customer to build their confidence and increase their English proficiency.
                      </Element>
                      <Element>
                        Using language that is appropriate to the English level of the Customer.
                      </Element>
                      <Element>
                        Delivering loosely structured content; encourage direct conversation; and present opportunities for Customers to enhance their communication skills for the purpose of helping to improve the Customer’s English proficiency.
                      </Element>
                      <Element>
                        Assisting the Customer in completing the English language modules provided to the Customer via the Reallyenglish online platform.
                      </Element>
                      <Element>
                        Adhering to any of the standard policies, terms and conditions, contracts, etc. that the Mentor currently delivers to the VHE approved Supplier.
                      </Element>
                    </ol>
                  </Element>
                </ol>
              </Element>
              <Element>
                CANCELLATION
                <ol type="A">
                  <Element>
                    Any cancellations by Customers in line with the ‘VHE Cancellation & Refund Policy’ will result in effect, the contracted terms between MSI and the approved VHE Supplier.
                  </Element>
                  <Element>
                    Mentors that have a material grievance are subject to the terms of agreements between the approved VHE Supplier and the Mentor.
                  </Element>
                </ol>
              </Element>
              <Element>
                RESTRICTIONS OF SERVICE
                <ol type="A">
                  <Element>
                    The Mentor acknowledges that VHE Mentor Training and access to, and use of, Reallyenglish language content is provided exclusively for the VHE program. 
                  </Element>
                  <Element>
                    The Mentor agrees they will not utilise VHE Mentor Training materials or Reallyenglish language content to provide similar online English learning services.
                  </Element>
                  <Element>
                    The Mentor acknowledges they have been matched with a Customer (participant) to provide online English learning services exclusively for the VHE program, unless otherwise permitted by MSI. 
                  </Element>
                  <Element>
                    The Mentor agrees they will not make direct arrangements with VHE Customers (participants) to provide similar online English learning services.
                  </Element>
                </ol>
              </Element>
              <Element>
                OFFER WITHDRAWN BY VHE
                <ol type="A">
                  <Element>
                    Any withdrawn offers by MSI in line with the ‘VHE Cancellation & Refund Policy’ will result in effect, the contracted terms between MSI and the approved VHE Supplier.
                  </Element>
                  <Element>
                    Mentors that have a material grievance are subject to the terms of agreements between the approved VHE Supplier and the Mentor.
                  </Element>
                </ol>
              </Element>
              <Element>
                EXCLUSION
                <ol type="A">
                  <Element>
                    Any Customer exclusion by MSI in line with the ‘VHE Cancellation & Refund Policy’ will result in effect, the contracted terms between MSI and the approved VHE Supplier.
                  </Element>
                  <Element>
                    Mentors that have a material grievance are subject to the terms of agreements between the approved VHE Supplier and the Mentor.
                  </Element>
                </ol>
              </Element>
              <Element>
                CANCELLATION & TRANSFER – MENTOR NON-DELIVARBLES
                <ol type="A">
                  <Element>
                    MSI reserves the right to request the approved VHE Supplier to transfer and or terminate the program between a Customer and Mentor, at its discretion and without a required explanation to the Supplier or their Mentors.
                  </Element>
                  <Element>
                    Mentors that have a material grievance are subject to the terms of agreements between the approved VHE Supplier and the Mentor.
                  </Element>
                </ol>
              </Element>
              <Element>
                REVIEW PROCESS
                <ol type="A">
                  <Element>
                  Initially, a Mentor must satisfy the full review, grievance or refund policy of the approve VHE Supplier.
                  </Element>
                  <Element>
                  A Mentor has the right to escalate a grievance after satisfying clause 5.1.
                  </Element>
                  <Element>
                  The escalated grievance will be adjudicated under processes described in the ‘VHE Grievance Resolution Policy’.
                  </Element>
                  <Element>
                  If a Mentor is not satisfied with the outcome of a decision made under this policy, the review process described in the ‘VHE Grievance Resolution Policy’.
                  </Element>
                </ol>
              </Element>
            </ol>
          </div>
          <div id="grievance-resolution">
            <H3>VIRTUAL HOMESTAY ENGLISH (VHE) GRIEVANCE RESOLUTION POLICY</H3>
            <p>
              MyStay International Pty Ltd (“MSI”) is committed to providing affordable and accessible English programs for people across the globe. Feedback of all kinds is welcomed as this information helps us to identify what we are doing well and where we need to improve our services and systems.
            </p>
            <p>
              MSI has established policies and procedures to support its Customers, Mentors and staff to resolve legitimate complaints that cannot be settled informally. All complaints and grievances are taken seriously and are responded to in a timely manner.
            </p>
            <p>
              As a first step, we encourage you to read the
              {' '}
              <a href="#customer-fees">
                VIRTUAL HOMESTAY ENGLISH (VHE) CUSTOMER FEES AND CHARGES POLICY
              </a>
              ,{' '}
              <a href="#deferral-policy">
                VIRTUAL HOMESTAY ENGLISH (VHE) CANCELLATION & REFUND POLICY
              </a>{' '}
              and{' '}
              <a href="#mentor-deliver">
                VIRTUAL HOMESTAY ENGLISH (VHE) MENTOR DELIVERABLES
              </a>
              , so you can understand your options and how you might proceed.
            </p>
            <ol>
              <Element>
                  OVERVIEW
                <ol type="A">
                  <Element>
                    This policy outlines MyStay International Pty Ltd (“MSI”) grievance resolution process for either:
                    <ol type="i">
                      <Element>“Customers”</Element>
                      <Element>“Mentors”</Element>
                    </ol>
                  </Element>
                  <Element>
                    The Australian Homestay Network (“AHN”) is owned by MSI and is the key supplier of the VHE program. References to “VHE Supplier/s” include AHN.
                  </Element>
                  <Element>
                    A “Customer” is defined as:
                    <ol type="i">
                      <Element>
                        Someone who has applied for the VHE program as an English learning participant, received an invoice from AHN, and has made full payment of the invoice to AHN.
                      </Element>
                    </ol>
                  </Element>
                  <Element>
                      A “Mentor” is defined as:
                    <ol type="i">
                      <Element>
                        Having completed the “VHE Mentor Training program” available at the time and hold the supplied completion certificate (if applicable).
                      </Element>
                      <Element>
                        If training by the Mentor has eclipsed 12-month period since completion of the training, the Mentor has been reassessed by the Supplier as competently trained.
                      </Element>
                    </ol>
                  </Element>
                  <Element>
                    The VHE program consists of a scheduled program containing the following items either for the duration or intermittently throughout the program:
                    <ol type="i">
                      <Element>
                        One-on-One online lessons with a specially trained English-speaking host Mentor (minimum 28 hours over 4 weeks)
                      </Element>
                      <Element>
                        Cultural immersion
                      </Element>
                      <Element>
                        Access to the Reallyenglish online platform (16 weeks)
                      </Element>
                      <Element>
                        Certificate of Completion
                      </Element>
                    </ol>
                  </Element>
                  <Element>
                    All Customers and Mentors must accept and adhere to any MSI policies or policies of any VHE Suppliers, for the duration of the program. Policies are in effect at the time of application to the program.
                  </Element>
                  <Element>
                    ACADEMIC MATTERS – TECHNOLOGY
                    <ol type="i">
                      <Element>
                        Grievances about access to and use of any associated technology (including purchasing, enrolment and English language deliverables included in the VHE Package) are addressed, as appropriate, by MSI.
                      </Element>
                    </ol>
                  </Element>
                  <Element>
                    ACADEMIC MATTERS – MENTORING
                    <ol type="i">
                      <Element>
                        Grievances about the level of quality or engagement in the Mentoring elements of the VHE Package, by either Customer or Mentor (including availability, completion and One-on-One online English language use) are addressed, as appropriate, by MSI.
                      </Element>
                    </ol>
                  </Element>
                  <Element>
                    OTHER PACKAGED SERVICES
                    <ol type="i">
                      <Element>
                        Grievances about the level of quality or engagement in the additional elements of the VHE Package, by either Customer or Mentor (including training, insurances and orientation information) are addressed, as appropriate, by MSI.
                      </Element>
                    </ol>
                  </Element>
                  <Element>
                    MSE SUPPLIERS
                    <ol type="i">
                      <Element>
                        It is a condition of purchase that all Customers and Mentors adhere to the policy and procedure of any VHE Suppliers, This includes any English Language Education Providers, Insurance Providers, etc.
                      </Element>
                      <Element>
                        Some or all of a grievance may be subject to the policies and procedures of the VHE Supplier and will be addressed, as appropriate, by the relevant Supplier.
                      </Element>
                    </ol>
                  </Element>
                  <Element>
                      ADMINISTRATIVE MATTERS – CHARGES, FEES & DATA
                    <ol type="i">
                      <Element>
                        Grievances about costs, charges, fees or financial transactions by Customers are addressed, as appropriate, by MSI.
                      </Element>
                      <Element>
                        Grievances about payments or reimbursements by Mentors are addressed, as appropriate, by MSI.
                      </Element>
                    </ol>
                  </Element>
                  <Element>
                    CUSTOMER CONDUCT
                    <ol type="i">
                      <Element>
                        MSI reserves the right to request the Customer to transfer and or terminate the program, at its discretion and without a required explanation, to the Customer or their representatives.
                      </Element>
                      <Element>
                        Customers that have a material grievance are subject to the terms of agreements between the approved VHE Supplier and MSI.
                      </Element>
                    </ol>
                  </Element>
                </ol>
              </Element>
            </ol>
          </div>
        </Col>
      </ParagraphContainer>
    </Wrapper>
  </Layout>
);

export default TermsAndConditionsPage;
